import React from "react"

import WorkSheet from "../components/workSheet"
import Feedback from "../components/feedback"
import Nav from "../components/nav"

/* Dont need reader for demo as i have predefined files
const reader = new window.FileReader()
reader.addEventListener("onloadend", () => {})
*/

const Demo = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Nav />
      <WorkSheet />
      <Feedback />
    </div>
  )
}

export default Demo
